import React from "react";
import { UnityCore } from "../../../components";

const PracticePunctuation = (props) => {

  const { history } = props;
  const additionalPractice = {
    capitalize: false,
    practice: 'punctuation',
    symbol: '!',
    exercise: 'basic'
  };
  return (
    <div>
      <UnityCore history={history} additionalPractice={additionalPractice}></UnityCore>
    </div>
  );
};

export default PracticePunctuation;

import React from "react";
import useRoveFocus from "../../../useRoveFocus";
import { MenuButton } from "../../../components";
import SfxAdditional from "../../../assets/sounds/extra.mp3";
import SfxLetter from "../../../assets/sounds/letters.mp3";
import SfxWord from "../../../assets/sounds/woorden.mp3";
import SfxPunctuation from "../../../assets/sounds/leestekens.mp3";
import SfxNumbers from "../../../assets/sounds/cijfers.mp3";
import SfxBack from "../../../assets/sounds/nl/back.mp3";
import useSound from "use-sound";
import { Howler } from "howler";

const Menu = (props) => {
  const { history, match, practice } = props;
  const practiceEnabled = practice.practice_enabled;
  console.log(practiceEnabled);
  const { path } = match;

  const [playLetter] = useSound(SfxLetter, {
    interrupt: true,
  });
  // const [playWord] = useSound(SfxWord, {
  //   interrupt: true,
  // });
  const [playPunctuation] = useSound(SfxPunctuation, {
    interrupt: true,
  });
  // const [playNumbers] = useSound(SfxNumbers, {
  //   interrupt: true,
  // });
  const [playBack] = useSound(SfxBack, {
    interrupt: true,
  });
  const [focus, setFocus] = useRoveFocus(practiceEnabled ? 3 : 1);
  useSound(SfxAdditional, {
    autoplay: true,
  });

  const navigateTo = (path) => {
    history.push(path);
  };

  const navigateLetter = () => {
    navigateTo(`${path}/letter`);
  };

  // const navigateToWordExercises = () => {
  //   navigateTo(`${path}/word_exercises`);
  // };

  const navigateToPickPunctuation = () => {
    navigateTo(`${path}/pick_punctuation`);
  };

  // const navigateToPracticeNumbers = () => {
  //   navigateTo(`${path}/practice_numbers`);
  // };

  const navigateBack = () => {
    Howler.stop();
    history.push("/");
  };

  return (
    <div className="home-container">
      <div>
        <h1>Extra oefenen</h1>
        {practiceEnabled ? (
          <div className="flex-column">
            <MenuButton
              large
              ref={React.createRef()}
              value="Letters"
              labelText="Letters"
              onClick={navigateLetter}
              onFocus={playLetter}
              setFocus={setFocus}
              index={0}
              focus={focus === 0}
            />
            <MenuButton
              large
              ref={React.createRef()}
              value="Leestekens oefenen"
              labelText="Leestekens oefenen"
              onClick={navigateToPickPunctuation}
              onFocus={playPunctuation}
              setFocus={setFocus}
              index={1}
              focus={focus === 1}
            />
            <MenuButton large ref={React.createRef()} value="Terug" labelText="Terug" onClick={navigateBack} onFocus={playBack} setFocus={setFocus} index={2} focus={focus === 2} />
          </div>
        ) : (
          <div className="flex-column">
            <h2>Extra oefenen is pas beschikbaar vanaf module 2</h2>
            <MenuButton
              large
              ref={React.createRef()}
              value="Terug"
              labelText="Terug"
              onClick={history.goBack}
              onFocus={playBack}
              setFocus={setFocus}
              index={0}
              focus={focus === 0}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;

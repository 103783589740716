import React, { useState } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import useSound from 'use-sound';

import api from "../../services/api";
import StudentCode from "./StudentCode";
import LoginCode from "./LoginCode";

import SfxAuthorized from "../../assets/sounds/nl/login_yes.mp3";
import SfxUnauthorized from "../../assets/sounds/nl/login_no.mp3";

import "../../assets/styles/global.scss";
import "./authentication.css";

const Authentication = ({ history }) => {

  const [playUnauthorized] = useSound(SfxUnauthorized, {
    interrupt: true,
    onend: () => {
      history.replace("/")
    },
  });
  const [playAuthorized] = useSound(SfxAuthorized, {
    interrupt: true,
  });
  const [failedLogin, setFailedLogin] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [loginCode, setLoginCode] = useState("");
  const { path } = useRouteMatch();

  const onChangeUserCode = (value) => {
    setUserCode(value);
  };

  const onChangeLoginCode = (value) => {
    setLoginCode(value);
  };

  const handleLogin = () => {
    api.authenticate(userCode, loginCode).then(response => {
      if (response.ok) {
        const token = response.data.token;
        Cookies.set("__session", token)
        Cookies.set("first_page_after_login", true)
        playAuthorized();
      } else {
        setFailedLogin(true);
        setUserCode('');
        setLoginCode('');
        playUnauthorized();
      }

    });
  };

  const renderStudentCode = () => {
    const isAuthenticated = Cookies.get("__session");

    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <StudentCode value={userCode} onChangeValue={onChangeUserCode} />;
    }
  };

  const renderLoginCode = () => {
    const isAuthenticated = Cookies.get("__session");

    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else if (failedLogin) {
      return (
        <div className="center-container">
          <h1>Deze combinatie is niet juist</h1>
        </div>
      );
    } else {
      if (userCode !== "") {
        return <LoginCode value={loginCode} onChangeValue={onChangeLoginCode} handleLogin={handleLogin} />
      } else {
        return <Redirect to="/login" />;
      }

    }
  };

  return (
    <Switch>
      <Route exact path={path} render={renderStudentCode} />
      <Route path={`${path}/login_code`} render={renderLoginCode} />
    </Switch>
  );
};

export default Authentication;

import React, { Component } from "react";
import Unity, { UnityContent } from "react-unity-webgl";
import { Howl, Howler } from "howler";
import ProgressBar from "../components/ProgressBar";
import api from "../services/api";
import "../assets/styles/global.scss";
import SfxLoading from "../assets/sounds/nl/loading.mp3";
import SfxStarting from "../assets/sounds/nl/exercise_starting.mp3";
import SfxLoaded from "../assets/sounds/nl/exercise_loaded_short.mp3";

class UnityCore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: true,
      playerJson: "",
      childJson: "",
      progress: "",
      payload: {},
      pointer: "",
      loaded: false,
    };
    this.soundLoading = new Howl({ src: SfxLoading });
    this.soundStarting = new Howl({ src: SfxStarting });
    this.soundLoaded = new Howl({ src: SfxLoaded });
    this.loadingInterval = "";

    this.additionalPractice = props.additionalPractice || false;
    this.chapterId = props.chapter || null;
    this.unityContent = "";
    this.labelStyles = {
      padding: 5,
      color: "white",
      fontWeight: "bold",
      lineHeight: "50px",
      fontSize: 24,
    };
    console.log(this.history);
  }
  handleOnEnd() {
    this.setState({
      playing: false,
    });
  }
  focusOnPlayer() {
    console.log("focusOnPlayer");
  }
  handleBackClick() {
    this.props.history.push("/");
  }
  componentWillUnmount() {
    clearInterval(this.loadingInterval);
  }
  componentDidMount() {
    this.soundStarting.play();
    console.log("componentDidMount");

    this.loadingInterval = setInterval(() => {
      this.soundLoading.play();
    }, 10000);

    api.player().then((response) => {
      if (response.ok) {
        this.setState({ playerJson: response.data });
        this.createUnityContent();
        // this.goToOverview(true);
      }
    });
  }
  b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  handleResponse(data, delegateToUnity) {
    if (delegateToUnity === undefined || delegateToUnity === null) {
      delegateToUnity = false;
    }
    data = JSON.parse(this.b64DecodeUnicode(data.encoded));
    this.setState({ payload: data.payload });
    console.log("handleResponse");
    console.log("Response decoded:");
    console.log(data);
    this.setState({ pointer: data.progress.pointer });

    if (data.segment != null) {
      this.unityContent.send("CoreObject", "userData", JSON.stringify(data.user));
      if (!delegateToUnity) {
        this.unityContent.send("CoreObject", "start", JSON.stringify(data.segment));
      }
    } else {
      console.log("no segment");
    }
  }

  goToOverview(reload = false) {
    this.props.history.push(reload ? "/?reload=true" : "/");
  }

  handleServerError(xhr) {
    if (xhr.getResponseHeader("goToOverview") == "true") {
      this.props.history.push("/");
    }
  }

  createUnityContent() {
    console.log("createUnityContent");
    this.unityContent = new UnityContent(`${process.env.PUBLIC_URL}/player_build/WebGL.json`, `${process.env.PUBLIC_URL}/player_build/UnityLoader.js`, {
      modules: this.state.playerJson,
    });
    this.setState({ loaded: true });
    this.unityContent.on("progress", (progression) => {
      if (progression === 0) {
        this.unityContent.unityComponent.htmlElement.firstElementChild.classList.add("embed-responsive-item");
      }
      console.log(progression);
      this.setState({
        progress: progression,
      });
    });

    this.unityContent.on("loaded", () => {
      this.soundLoading.play();
      this.unityContent.send("CoreObject/AudioMgr", "VolumeOff");
      this.unityContent.send("CoreObject/AudioMgr", "VolumeOn");
      var globalAssets = JSON.stringify({
        globalAssets: this.unityContent.unityConfig.modules.globalAssets,
      });
      this.unityContent.send("CoreObject", "globalAssets", globalAssets);
      clearInterval(this.loadingInterval);
      // var data = JSON.stringify({ payload: this.state.payload });
      // var encoded = this.b64EncodeUnicode(data);

      api.child().then((response) => {
        if (response.ok) {
          this.setState({ pointer: response.data.pointer });
          console.log(response.data);
          this.unityContent.send(
            "CoreObject",
            "env",
            JSON.stringify({
              env: "development",
              productUuid: response.data.product_uuid,
              postFpsData: false,
              color0: response.data.color0,
              color1: response.data.color1,
              color2: response.data.color2,
              color3: response.data.color3,
              fontSize: response.data.font_size,
              blurAmount: response.data.blur_amount,
              desaturationAmount: response.data.desaturation_amount,
              applyColor: response.data.apply_color,
              defaultCpm: response.data.default_cpm,
              globalVolume: 1,
              musicVolume: response.data.background_sound ? 0.15 : 0,
              ambientVolume: 0.15,
              uiVolume: 1,
              wordsVolume: 1,
              sfxVolume: 0.33,
            })
          );

          if (this.additionalPractice) {
            var payload = this.additionalPractice;
            api
              .playerInitialized("/child/exercise/initialized?capitalize=true&exercise=basic&practice=problematic", {
                chapter: response.data.current_chapter,
                payload: payload,
              })
              .then((response) => {
                if (response.ok) {
                  this.handleResponse(response.data);
                }
              });
          } else {
            let chapter = this.chapterId || response.data.current_chapter;
            api
              .playerInitialized("/child/exercise/initialized", {
                chapter: chapter,
              })
              .then((response) => {
                if (response.ok) {
                  this.handleResponse(response.data);
                }
              });
          }
        }
      });
    });

    this.unityContent.on("onCompleted", (result) => {
      var parsedResult = JSON.parse(result);
      var data = JSON.stringify({ data: parsedResult, payload: this.state.payload });
      var encoded = this.b64EncodeUnicode(data);

      api
        .playerCompleted("/child/exercise/completed", encoded)
        .then((data) => {
          console.log(data);
          if (parsedResult.redirectToOverview) {
            this.goToOverview();
          } else {
            var delegateToUnity, ref;
            delegateToUnity = (ref = result.type) === "endOfDay" || ref === "goToOverview";

            this.handleResponse(data.data, delegateToUnity);
          }
        })
        .catch((error) => {
          console.log(data);
          console.log("handleServerError called from onCompleted");
          this.handleServerError(error);
        });
    });

    this.unityContent.on("resize", () => {
      console.log("resize");
    });

    this.unityContent.on("onReadyToPlay", () => {
      // this.soundLoading.stop();
      // this.soundLoaded.play();

      clearInterval(this.loadingInterval);
      console.log("onReadyToPlay");
    });

    this.unityContent.on("onFailed", () => {
      console.log("onFailed");
    });

    this.unityContent.on("onQuit", () => {
      this.props.history.push("/");
      console.log("onQuit");
    });

    this.unityContent.on("quitted", () => {
      console.log("quittedquittedquittedquitted");
    });
  }

  render() {
    return (
      <div className="flex-container" onClick={this.focusOnPlayer}>
        <div className="container">
          {this.state.progress !== 1 ? (
            <div>
              <span
                ref={(input) => input && input.focus()}
                role="progressbar"
                aria-valuenow={Math.round(this.state.progress * 100)}
                aria-live="polite"
                aria-valuemin="0"
                aria-valuemax="100"
                tabIndex="-1"
                className="progress-input color-scheme-border"
                style={this.labelStyles}
              >
                {Math.round(this.state.progress * 100)}
              </span>
              <ProgressBar bgcolor={"#000000"} completed={Math.round(this.state.progress * 100)} />
            </div>
          ) : (
            <div className="jaap"></div>
          )}
          {this.state.loaded ? (
            <Unity unityContent={this.unityContent} className="unity-container color-scheme-border embed-responsive embed-responsive-16by9" />
          ) : (
            <div className="unity-container color-scheme-border loading-player embed-responsive embed-responsive-16by9"></div>
          )}
          <div className="flex">
            <div>
              <input
                className="button-medium color-scheme-border mb-0"
                type="submit"
                value="Terug naar het overzicht"
                onClick={() => {
                  this.handleBackClick();
                }}
              />
            </div>
            <span>Pointer: {this.state.pointer}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default UnityCore;

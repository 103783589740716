import React, { useRef, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import api from "../../services/api";
import useSound from "use-sound";
import { Howler } from "howler";
import { MenuButton } from "../../components";
import useRoveFocus from "../../useRoveFocus";
import SfxMainMenuIntro from "../../assets/sounds/nl/main_menu_intro.mp3";
import SfxMainMenu from "../../assets/sounds/nl/main_menu.mp3";
import SfxStart from "../../assets/sounds/nl/main_start.mp3";
import SfxSetting from "../../assets/sounds/nl/main_settings.mp3";
import SfxAdditional from "../../assets/sounds/nl/main_extra.mp3";
import SfxExam from "../../assets/sounds/nl/main_exam.mp3";
import SfxStop from "../../assets/sounds/nl/main_exit.mp3";

const Home = ({ history }) => {
  const KeyValue = window.TypeTopia.KeyValue;
  const authInputRef = useRef(null);
  const examInputRef = useRef(null);
  const [examOpen, setExamOpen] = useState(false);

  useEffect(() => {
    var pointer = KeyValue.get("pointer").split(".");
    console.log(pointer);
    if (pointer[0] === "2147483647" && pointer[1] === "2147483647") {
      setExamOpen(true);
    }
    let reload = false;
    reload = new URLSearchParams(history.location.search).get("reload");
    if (reload) {
      api.getExams().then((response) => {
        if (response.ok) {
          const openExams = response.data.find((e) => e.status != "locked");
          history.replace("/");
          setExamOpen(!!openExams);
        } else {
          if (response.status == 401) {
          }
        }
      });
    }
  }, []);
  const [playMainMenu] = useSound(SfxMainMenu, {
    interrupt: true,
    autoplay: !Cookies.get("first_page_after_login"),
  });
  const [playMainMenuIntro, playMainMenuIntroSound] = useSound(SfxMainMenuIntro, {
    interrupt: true,
    autoplay: Cookies.get("first_page_after_login"),
    onend: () => {
      console.info("Sound ended!");
      Cookies.remove("first_page_after_login");
    },
    onstop: () => {
      console.info("Sound stopped!");
      Cookies.remove("first_page_after_login");
    },
  });
  const [playStart, playStartSound] = useSound(SfxStart, {
    interrupt: true,
  });
  const [playSetting, playSettingSound] = useSound(SfxSetting, {
    interrupt: true,
  });
  const [playAdditional, playAdditionalSound] = useSound(SfxAdditional, {
    interrupt: true,
  });
  const [playExam, playExamSound] = useSound(SfxExam, {
    interrupt: true,
  });
  const [playStop] = useSound(SfxStop, {
    interrupt: true,
  });

  const [focus, setFocus] = useRoveFocus(5);
  const logout = () => {
    Howler.stop();
    Cookies.remove("__session");
    history.replace("/");
  };

  const navigateTo = (path) => {
    history.push(path);
  };

  const navigateToStartCourse = () => {
    playMainMenuIntroSound.stop();
    playStartSound.stop();
    navigateTo("/course");
  };

  const navigateToExtraPractice = () => {
    playAdditionalSound.stop();
    navigateTo("/extra_practice");
  };

  const navigateToSettings = () => {
    playSettingSound.stop();
    navigateTo("/settings");
  };

  const navigateToExam = () => {
    playExamSound.stop();
    navigateTo("/exam");
  };

  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget) {
      examOpen ? examInputRef.current.focus() : authInputRef.current.focus();
    }
  };

  return (
    <div className="home-container" onClick={onBodyClickHandler}>
      <div>
        <h1>Hoofdmenu</h1>
        <div className="flex-column">
          {!examOpen ? (
            <MenuButton
              large
              ref={authInputRef}
              value="Start de cursus"
              labelText="Start de cursus"
              onClick={navigateToStartCourse}
              onFocus={playStart}
              setFocus={setFocus}
              index={0}
              focus={focus === 0}
            />
          ) : (
            <MenuButton
              large
              ref={examInputRef}
              value="Examen doen"
              labelText="Examen doen"
              onFocus={playExam}
              onClick={navigateToExam}
              setFocus={setFocus}
              index={0}
              focus={focus === 0}
            />
          )}
          <MenuButton
            large
            ref={React.createRef()}
            value="Extra oefenen"
            labelText="Extra oefenen"
            onFocus={playAdditional}
            onClick={navigateToExtraPractice}
            setFocus={setFocus}
            index={1}
            focus={focus === 1}
          />
          <MenuButton
            large
            ref={React.createRef()}
            value="Instellingen"
            labelText="Instellingen"
            onFocus={playSetting}
            onClick={navigateToSettings}
            setFocus={setFocus}
            index={2}
            focus={focus === 2}
          />
          <MenuButton large ref={React.createRef()} value="Afsluiten" labelText="Afsluiten" onFocus={playStop} onClick={logout} setFocus={setFocus} index={3} focus={focus === 3} />
          <div className="text-center">VIP - v{global.appVersion}</div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);

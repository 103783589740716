import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 50,
    width: "100%",
    // backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginBottom: 20,
    // marginTop: -90,

  };

  const fillerStyles = {
    height: "100%",
    width: `${completed < 7 ? 7 : completed}%`,
    // backgroundColor: bgcolor,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "center",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
    lineHeight: "50px",
    fontSize: 24,
  };

  return (
    <div className="progress-container color-scheme-border" style={containerStyles}>
      <div className="progress-filler color-scheme-bg" style={fillerStyles}>
        <span className="progress-text color-scheme-text" style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;

import React, { useRef, useEffect, useState } from "react";
import api from "../../services/api";
import { withRouter } from "react-router-dom";
import useRoveFocus from "../../useRoveFocus";
import { MenuButton } from "../../components";
import SfxTextCurrent from "../../assets/sounds/nl/font_current.mp3";
import SfxText from "../../assets/sounds/nl/settings_font.mp3";
import SfxSmall from "../../assets/sounds/nl/font_large.mp3";
import SfxNormal from "../../assets/sounds/nl/font_larger.mp3";
import SfxLarge from "../../assets/sounds/nl/font_largest.mp3";
import SfxBack from "../../assets/sounds/nl/back.mp3";
import SfxSaved from "../../assets/sounds/nl/saved.mp3";
import useSound from "use-sound";
import { Howl, Howler } from "howler";

const FontSettings = ({ history }) => {
  const [currentValue, setCurrentValue] = useState(false);
  const [saving, setSaving] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [playSmall] = useSound(SfxSmall, {
    interrupt: true,
  });
  // eslint-disable-next-line no-unused-vars
  const [playNormal] = useSound(SfxNormal, {
    interrupt: true,
  });
  // eslint-disable-next-line no-unused-vars
  const [playLarge] = useSound(SfxLarge, {
    interrupt: true,
  });
  const [playBack] = useSound(SfxBack, {
    interrupt: true,
  });
  const [playSaved] = useSound(SfxSaved, {
    interrupt: true,
    onend: () => {
      navigateToSettings();
    },
  });
  var soundSmall = new Howl({ src: SfxSmall });
  var soundNormal = new Howl({ src: SfxNormal });
  var soundLarge = new Howl({ src: SfxLarge });
  var soundTextCurrent = new Howl({ src: SfxTextCurrent });

  const [focus, setFocus] = useRoveFocus(4);
  const authInputRef = useRef(null);
  const KeyValue = window.TypeTopia.KeyValue;

  useEffect(() => {
    setCurrentValue(KeyValue.get("font_size"));
    var currentFontSize = KeyValue.get("font_size");

    soundTextCurrent.on("end", function () {
      if (currentFontSize === "large") {
        soundLarge.play();
      } else if (currentFontSize === "medium") {
        soundNormal.play();
      } else {
        soundSmall.play();
      }
    });
    var soundText = new Howl({
      src: SfxText,
      autoplay: true,
      onend: function () {
        soundTextCurrent.play();
      },
    });
  }, []);

  const fontSizeFocus = (fontSize) => {
    Howler.stop();
    if (fontSize === "large") {
      soundLarge.play();
    } else if (fontSize === "medium") {
      soundNormal.play();
    } else {
      soundSmall.play();
    }
  };

  const changeFontSize = (str) => {
    setSaving(true);
    setCurrentValue(str);
    api.setKeyValue("font_size", str).then((response) => {
      if (response.ok) {
        KeyValue.set("font_size", str);
        setCurrentValue(KeyValue.get("font_size"));
        console.log("changeFontSize ok");
        playSaved();
      }
    });
  };
  const navigateToSettings = () => {
    Howler.stop();
    history.push("/settings");
  };
  const navigateBack = () => {
    Howler.stop();
    history.push("/settings");
  };

  return (
    <div>
      <div className="home-container">
        <div>
          <h1>Lettergrootte</h1>
          <h1>Huidig: {currentValue === "medium" ? "Groter" : currentValue === "large" ? "Grootst" : "Groot"} </h1>
          {saving ? (
            <div className="flex-column saving">
              <h1>Opslaan...</h1>
            </div>
          ) : (
            <div className="flex-column">
              <MenuButton
                large
                ref={authInputRef}
                value="Groot"
                labelText="Groot"
                onClick={() => changeFontSize("small")}
                onFocus={() => fontSizeFocus("small")}
                setFocus={setFocus}
                index={0}
                focus={focus === 0}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Groter"
                labelText="Groter"
                onClick={() => changeFontSize("medium")}
                onFocus={() => fontSizeFocus("medium")}
                setFocus={setFocus}
                index={1}
                focus={focus === 1}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Grootst"
                labelText="Grootst"
                onClick={() => changeFontSize("large")}
                onFocus={() => fontSizeFocus("large")}
                setFocus={setFocus}
                index={2}
                focus={focus === 2}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Terug"
                labelText="Terug"
                onClick={navigateBack}
                onFocus={playBack}
                setFocus={setFocus}
                index={3}
                focus={focus === 3}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(FontSettings);

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Switch, Route, withRouter, useRouteMatch } from "react-router-dom";
import "../../globals";
import api from "../../services/api";
import "./dashboard.css";
import Home from "./Home";
import Course from "./Course";
import Exam from "./Exam";
import ExtraPractice from "./ExtraPractice";
import Settings from "./Settings";
import SpeedSettings from "./SpeedSettings";
import FontSettings from "./FontSettings";
import ColorSettings from "./ColorSettings";
import BackgroundSettings from "./BackgroundSettings";
import SoundSettings from "./SoundSettings";

const Dashboard = ({ history }) => {
  const { path } = useRouteMatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    api.getKeyValues().then((response) => {
      if (response.ok) {
        console.log("!!!api.getKeyValues()");
        window.TypeTopia.KeyValue = new window.KeyValueStore(response.data.encoded);
        var colorScheme = window.TypeTopia.KeyValue.get("color_scheme") || 0;
        document.body.classList.add(`color-scheme-${colorScheme}`);
        setLoaded(true);
      } else {
        if (response.status == 401) {
          Cookies.remove("__session");
          history.replace("/");
        }
      }
    });
  }, []);
  return (
    <div>
      {loaded ? (
        <Switch>
          <Route exact path={path} component={Home} />
          <Route path={"/course"} component={Course} />
          <Route path={"/exam"} component={Exam} />
          <Route path={"/extra_practice"} component={ExtraPractice} />
          <Route path={"/settings"} component={Settings} />
          <Route path={"/speed-settings"} component={SpeedSettings} />
          <Route path={"/font-settings"} component={FontSettings} />
          <Route path={"/color-settings"} component={ColorSettings} />
          <Route path={"/background-settings"} component={BackgroundSettings} />
          <Route path={"/sound-settings"} component={SoundSettings} />
        </Switch>
      ) : (
        <div className="center-container">
          <h1>Laden...</h1>
        </div>
      )}
    </div>
  );
};

export default withRouter(Dashboard);

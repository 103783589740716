import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Cookies from "js-cookie";

import Authentication from "./features/Authentication";
import Dashboard from "./features/Dashboard";

const AppRouter = () => {
  const getInitialRoute = () => {
    const isAuthenticated = Cookies.get("__session");

    return isAuthenticated ? <Dashboard /> : <Redirect to="/login" />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Authentication} />
        <Route path="/" render={getInitialRoute} />
      </Switch>
    </Router>
  );
}

export default AppRouter;

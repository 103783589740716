import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import api from "../../../services/api";
import Menu from "./Menu";
import Letter from "./Letter";
import PracticePunctuation from "./PracticePunctuation";
import PickPunctuation from "./PickPunctuation";

const ExtraPractice = () => {
  const { path } = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [practice, setPractice] = useState(false);

  useEffect(() => {
  	api.getAdditionalPractice().then((response) => {
      if (response.ok) {
        setPractice(response.data)
        setLoaded(true)
      }
    });
  },[])
  return (
    <div>
      {loaded ? (
        <Switch>
          <Route path={path} exact render={(props) => (
              <Menu {...props} practice={practice} />
            )}
          />
          <Route
            path={`${path}/letter`}
            render={(props) => (
                <Letter {...props} practice={practice} />
              )}
            />
          <Route
            path={`${path}/pick_punctuation`}
            render={(props) => (
                <PickPunctuation {...props} practice={practice} />
              )}
            />
          <Route
            path={`${path}/practice_punctuation`}
            render={(props) => (
                <PracticePunctuation {...props} practice={practice} />
              )}
            />

        </Switch>
      ) : (
        <div className="center-container">
          <h1>Laden...</h1>
        </div>
      )}
    </div>

  )
};

export default ExtraPractice;

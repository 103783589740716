import React, { useRef, useEffect } from "react";
import useSound from "use-sound";
import { Howl, Howler } from "howler";
import { Button } from "../../components";
import "./authentication.css";

import SfxStart from "../../assets/sounds/nl/login_pw_nr.mp3";
import SfxNumbers from "../../assets/sounds/nl/numbers.mp3";
import SfxBeep from "../../assets/sounds/beep.mp3";
import SfxContinue from "../../assets/sounds/nl/login_space.mp3";

const LoginCode = ({ value, onChangeValue, handleLogin }) => {
  const label = "Inlogcode";
  const loginRef = useRef(null);
  const loginButtonRef = useRef(null);
  const soundContinue = new Howl({ src: SfxContinue });
  const [playBeep] = useSound(SfxBeep);
  // const [playContinue] = useSound(SfxContinue, {
  //   interrupt: true,
  // });

  const [playNumbers] = useSound(SfxNumbers, {
    interrupt: true,
    sprite: {
      0: [0, 679],
      1: [1000, 679],
      2: [2000, 613],
      3: [3000, 631],
      4: [4000, 531],
      5: [5000, 652],
      6: [6000, 713],
      7: [7000, 746],
      8: [8000, 581],
      9: [9000, 838],
    },
    onend: () => {
      if (loginRef.current && loginRef.current.value.length === 4) {
        loginButtonRef.current.focus();
        soundContinue.play();
      }
    },
  });

  useEffect(() => {
    loginRef.current.focus();
    Howler.stop();
    const intro = new Howl({
      src: SfxStart,
      autoplay: true,
      interrupt: true,
      onend: () => {
        if (loginRef.current && loginRef.current.value.length === 4) {
          loginButtonRef.current.focus();
        }
      },
    });
  }, []);

  const onChangeHandler = (e) => {
    if (e.nativeEvent.data === " ") {
      e.preventDefault();
      if (e.target.value.length === 5) {
        handleLogin();
      }
      return;
    }
    Howler.stop();
    if (e.nativeEvent.data !== null && e.nativeEvent.data >= 0 && e.nativeEvent.data <= 9) {
      playNumbers({ id: e.nativeEvent.data });
    } else {
      if (e.nativeEvent.data !== null) {
        playBeep();
        return;
      }
    }
    onChangeValue(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget) {
      loginRef.current.focus();
    }
  };

  return (
    <div className="center-container" onClick={onBodyClickHandler}>
      <h1>{label}</h1>
      <form className="form-container">
        <input
          ref={loginRef}
          className="authentication-input"
          type="text"
          // maxLength="4"
          aria-label={label}
          aria-required="true"
          aria-describedby="inputDescLogin"
          autoComplete="off"
          onChange={onChangeHandler}
          value={value}
          name={label}
        />
        <span id="inputDescLogin" aria-live="assertive"></span>
        <Button ref={loginButtonRef} type="submit" value="Inloggen" labelText="Inloggen" onClick={onSubmit} />
      </form>
    </div>
  );
};

export default LoginCode;

import React, { useState, useEffect, useCallback } from "react";
import SfxBack from "../../../assets/sounds/nl/back.mp3";
import SfxPunctuation from "../../../assets/sounds/nl/punctuation/punt.mp3";
import SfxPunctuation1 from "../../../assets/sounds/nl/punctuation/komma.mp3";
import SfxPunctuation2 from "../../../assets/sounds/nl/punctuation/puntkomma.mp3";
import SfxPunctuation3 from "../../../assets/sounds/nl/punctuation/punten.mp3";
import SfxPunctuation4 from "../../../assets/sounds/nl/punctuation/uitroepteken.mp3";
import SfxPunctuation5 from "../../../assets/sounds/nl/punctuation/vraagteken.mp3";
import useSound from "use-sound";
import { KeyButton, MenuButton } from "../../../components";
import { Howler, Howl } from "howler";

const PickPunctuation = (props) => {
  const { history } = props;

  const punctuationKeys = [".", ",", ";", ":", "!", "?"];

  var punctuationSound = new Howl({ src: SfxPunctuation });
  var punctuationSound1 = new Howl({ src: SfxPunctuation1 });
  var punctuationSound2 = new Howl({ src: SfxPunctuation2 });
  var punctuationSound3 = new Howl({ src: SfxPunctuation3 });
  var punctuationSound4 = new Howl({ src: SfxPunctuation4 });
  var punctuationSound5 = new Howl({ src: SfxPunctuation5 });

  const punctuationKeySounds = [punctuationSound, punctuationSound1, punctuationSound2, punctuationSound3, punctuationSound4, punctuationSound5];

  const rowSize = 2;
  const colSize = punctuationKeys.length;

  const [currentRowFocus, setCurrentRowFocus] = useState(-1);
  const [currentColFocus, setCurrentColFocus] = useState(0);

  const [playBack] = useSound(SfxBack, {
    interrupt: true,
  });

  const navigateTo = (path) => {
    history.push(path);
  };

  const onKeyClick = (letter) => {
    navigateToPracticePunctuation(letter.target.value);
  };

  const playPunctuation = (letter) => {
    console.log(letter);
    Howler.stop();
    punctuationKeySounds[letter].play();
  };

  const navigateToPracticePunctuation = (symbol) => {
    const encodedSymbol = encodeURIComponent(symbol);
    navigateTo(`practice_punctuation?symbol=${encodedSymbol}`);
  };

  const handleKeyDown = useCallback(
    (e) => {
      console.log(e.keyCode);
      if (e.keyCode === 40) {
        // Down arrow
        e.preventDefault();
        setCurrentRowFocus(currentRowFocus === rowSize - 1 ? 0 : currentRowFocus + 1);
      } else if (e.keyCode === 38) {
        // Up arrow
        e.preventDefault();
        setCurrentRowFocus(currentRowFocus === 0 ? rowSize - 1 : currentRowFocus - 1);
      }

      if (e.keyCode === 39) {
        // Right arrow
        e.preventDefault();
        setCurrentColFocus(currentColFocus === colSize - 1 ? 0 : currentColFocus + 1);
      } else if (e.keyCode === 37) {
        // Left arrow
        e.preventDefault();
        setCurrentColFocus(currentColFocus === 0 ? colSize - 1 : currentColFocus - 1);
      }
    },
    [rowSize, currentRowFocus, setCurrentRowFocus, colSize, currentColFocus, setCurrentColFocus]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return (
    <div>
      <div className="home-container">
        <div className="flex-column container-width">
          <h1>Leesteken</h1>
          <p>Met welk leesteken wil je oefenen?</p>
          <div className="flex-row mb-2">
            {punctuationKeys.map((letter, i) => {
              return (
                <KeyButton
                  ref={React.createRef()}
                  onClick={onKeyClick}
                  key={i}
                  onFocus={() => playPunctuation(currentColFocus)}
                  setFocus={() => ({})}
                  labelText={letter}
                  focus={currentRowFocus === 0 && currentColFocus === i}
                  value={i}
                >
                  {letter}
                </KeyButton>
              );
            })}
          </div>

          <MenuButton
            large
            ref={React.createRef()}
            value="Terug"
            labelText="Terug"
            onClick={history.goBack}
            onFocus={playBack}
            setFocus={() => ({})}
            index={1}
            focus={currentRowFocus === 1}
            tabIndex={currentRowFocus === 1 ? 0 : -1}
          />
        </div>
      </div>
    </div>
  );
};

export default PickPunctuation;

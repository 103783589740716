import React from "react";
import { PageContainer, UnityCore, ExerciseProgress } from "../../components";

const Course = ({ history }) => {
  return (
    <div>
      <ExerciseProgress ></ExerciseProgress>
      <UnityCore history={history}></UnityCore>
    </div>
  );
};

export default Course;

import React, { useRef, useEffect } from "react";
import { withRouter, useRouteMatch } from "react-router-dom";
import {Howl, Howler} from 'howler';
import useSound from 'use-sound';

import { Button } from "../../components";
import "./authentication.css";
import SfxStart from "../../assets/sounds/nl/login_id_nr.mp3";
import SfxContinue from "../../assets/sounds/nl/login_space.mp3";
import SfxNumbers from "../../assets/sounds/nl/numbers.mp3";
import SfxBeep from "../../assets/sounds/beep.mp3";

const StudentCode = ({ value, onChangeValue, history }) => {
  const label = "Cursistnummer";
  const { path } = useRouteMatch();
  const authInputRef = useRef(null);
  const authButtonRef = useRef(null);
  const [playBeep] = useSound(SfxBeep);
  const soundContinue = new Howl({src: SfxContinue});
  // eslint-disable-next-line no-unused-vars
  const [play, {stop}] = useSound(SfxStart, {
    autoplay: true,
    interrupt: true,
    onend: () => {
      if (authInputRef.current) {
        authInputRef.current.focus();
      }
    },
  });
  const [playNumbers] = useSound(SfxNumbers, {
    interrupt: true,
    sprite: {
      0: [0, 679],
      1: [1000, 679],
      2: [2000, 613],
      3: [3000, 631],
      4: [4000, 531],
      5: [5000, 652],
      6: [6000, 713],
      7: [7000, 746],
      8: [8000, 581],
      9: [9000, 838],
    },
    onend: () => {
      if (authInputRef.current && authInputRef.current.value.length === 4) {
        authButtonRef.current.focus();
        soundContinue.play();
      }
    },
  });
  useEffect(() => {
    authInputRef.current.focus();
  },[])

  const onChangeHandler = (e) => {
    if (e.nativeEvent.data === " ") {
      onClickHandler();
      return
    }
    stop();
    Howler.stop();
    if (e.nativeEvent.data !== null && e.nativeEvent.data >= 0 && e.nativeEvent.data <= 9) {
      playNumbers({ id: e.nativeEvent.data });
    } else {
      if (e.nativeEvent.data !== null) {
        playBeep();
        return
      }
    }
    onChangeValue(e.target.value);
  };

  const onClickHandler = (e) => {
    if (authInputRef.current && authInputRef.current.value.length === 4) {
      stop();
      history.push(`${path}/login_code`);
    } else {
      e.preventDefault()
      authInputRef.current.focus();
      return
    }
  };

  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget){
      authInputRef.current.focus();
    }
  };

  return (
    <div className="center-container" onClick={onBodyClickHandler}>
      <h1>{label}</h1>
      <form className="form-container">
        <input
          ref={authInputRef}
          className="authentication-input"
          type="text"
          maxLength="4"
          aria-label={label}
          aria-required="true"
          aria-describedby="inputDescLogin"
          autoComplete="off"
          onChange={(e) => { onChangeHandler(e)}}
          value={value}
          name={label}
        />
        <span id="inputDescLogin" aria-live="assertive"></span>
        <Button
          ref={authButtonRef}
          type="submit"
          value="Verder"
          labelText="Verder"
          onClick={onClickHandler}
        />
      </form>
    </div>
  );
};

export default withRouter(StudentCode);

import React, { useRef, useEffect, useState } from "react";
import api from "../../services/api";
import { withRouter } from "react-router-dom";
import useRoveFocus from "../../useRoveFocus";
import { MenuButton, MenuSlider } from "../../components";
import SfxBackground from "../../assets/sounds/nl/background_menu.mp3";
import SfxLowest from "../../assets/sounds/nl/lowest.mp3";
import SfxLow from "../../assets/sounds/nl/low.mp3";
import SfxDefault from "../../assets/sounds/nl/average.mp3";
import SfxHigh from "../../assets/sounds/nl/high.mp3";
import SfxHigest from "../../assets/sounds/nl/highest.mp3";
import SfxDesaturation from "../../assets/sounds/nl/background_contrast.mp3";
import SfxBlur from "../../assets/sounds/nl/background_blur.mp3";
import SfxOn from "../../assets/sounds/nl/background_static_on.mp3";
import SfxOff from "../../assets/sounds/nl/background_static_off.mp3";
import SfxBack from "../../assets/sounds/nl/back.mp3";
import SfxSaved from "../../assets/sounds/nl/saved.mp3";
import useSound from 'use-sound';

const BackgroundSettings = ({ history }) => {
  const [currentValue, setCurrentValue] = useState(false);
  const [currentDesaturationValue, setCurrentDesaturationValue] = useState(false);
  const [currentBlurValue, setCurrentBlurValue] = useState(false);
  const [saving, setSaving] = useState(false);
  useSound(SfxBackground, {
    autoplay: true,
    interrupt: true,
  });
  const [playLowest] = useSound(SfxLowest, {
    interrupt: true,
  });
  const [playLow] = useSound(SfxLow, {
    interrupt: true,
  });
  const [playDefault] = useSound(SfxDefault, {
    interrupt: true,
  });
  const [playHigh] = useSound(SfxHigh, {
    interrupt: true,
  });
  const [playHigest] = useSound(SfxHigest, {
    interrupt: true,
  });

  const [playDesaturation] = useSound(SfxDesaturation, {
    interrupt: true,
  });
  const [playBlur] = useSound(SfxBlur, {
    interrupt: true,
  });
  const [playOn] = useSound(SfxOn, {
    interrupt: true,
  });
  const [playOff] = useSound(SfxOff, {
    interrupt: true,
  });

  const [playBack, backSound] = useSound(SfxBack, {
    interrupt: true
  });
  const [playSaved] = useSound(SfxSaved, {
    interrupt: true,
    onend: () => {
      navigateToSettings();
    },
  });
  const [focus, setFocus] = useRoveFocus(5);
  const authInputRef = useRef(null);
  const desInputRef = useRef(null);
  const blurInputRef = useRef(null);
  const KeyValue = window.TypeTopia.KeyValue;


  useEffect(() => {
    setCurrentValue(KeyValue.get('apply_color') || false);
    setCurrentDesaturationValue(KeyValue.get('desaturation_amount'));
    setCurrentBlurValue(KeyValue.get('blur_amount'));
  },[])

  const playValue = (value) => {
    switch(value.toString()) {
      case '0':
        playLowest()
        break;
      case '0.25':
        playLow();
        break;
      case '0.5':
        playDefault();
        break;
      case '0.75':
        playHigh();
        break;
      case '1':
        playHigest();
        break;
      default:
        // code block
    }
  };

  const navigateToSettings = () => {
    history.push("/settings");
  };

  const navigateBack = () => {
    backSound.stop();
    history.push('/settings')
  };

  const changeBlurAmount = (e) => {
    var blurValue = e.target.valueAsNumber;
    setCurrentBlurValue(blurValue);
    playValue(blurValue);
  };
  const saveBlurAmount = (e) => {
    setSaving(true);
    var blurValue = e.target.valueAsNumber;
    setCurrentBlurValue(blurValue);
    api.setKeyValue("blur_amount", blurValue).then((response) => {
      if (response.ok) {
        KeyValue.set('blur_amount', blurValue);
        console.log("blur_amount ok");
        playSaved();
      }
    });
  };
  const changeDesaturationAmount = (e) => {
    var desaturationValue = e.target.valueAsNumber;
    setCurrentDesaturationValue(desaturationValue);
    playValue(desaturationValue);
  };
  const saveDesaturationAmount = (e) => {
    setSaving(true);
    var desaturationValue = e.target.valueAsNumber;
    setCurrentDesaturationValue(desaturationValue);
    api.setKeyValue("desaturation_amount", desaturationValue).then((response) => {
      if (response.ok) {
        KeyValue.set('desaturation_amount', desaturationValue);
        playSaved();
      }
    });
  };
  const changeApplyColor = (str) => {
    setSaving(true);
    KeyValue.set('apply_color', str.toString());
    setCurrentValue(str.toString());
    api.setKeyValue("apply_color", str).then((response) => {
      if (response.ok) {
        KeyValue.set('apply_color', str);
        console.log("apply_color ok");
        playSaved();
      }
    });
  };
  return (
    <div>
      <div className="home-container">
        <div>
          <h1>Achtergrond</h1>

          {saving ? (
            <div className="flex-column saving">
              <h1>Opslaan...</h1>
            </div>
          ) : (
            <div className="flex-column">
              <h1>Vaste achtergrond kleur</h1>
              <h2>Huidig: {currentValue.toString() === 'true' ? ('Aan') : ('Uit')}</h2>
              <MenuButton
                large
                ref={authInputRef}
                value="Vaste kleur - AAN"
                labelText="Vaste kleur - AAN"
                onClick={() => changeApplyColor(true)}
                onFocus={playOn}
                setFocus={setFocus}
                index={0}
                focus={focus === 0}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Vaste kleur - UIT"
                labelText="Vaste kleur - UIT"
                onClick={() => changeApplyColor(false)}
                onFocus={playOff}
                setFocus={setFocus}
                index={1}
                focus={focus === 1}
              />
              <br></br>
              <br></br>
              <h2>Contrast</h2>
              <MenuSlider
                large
                ref={desInputRef}
                value={currentDesaturationValue}
                labelText="Terug"
                onClick={saveDesaturationAmount}
                onChange={changeDesaturationAmount}
                onFocus={playDesaturation}
                setFocus={setFocus}
                index={2}
                focus={focus === 2}
              />
              <br></br>
              <br></br>
              <h2>Vervagen</h2>
              <MenuSlider
                large
                ref={React.createRef()}
                value={currentBlurValue}
                labelText="Terug"
                onClick={saveBlurAmount}
                onChange={changeBlurAmount}
                onFocus={playBlur}
                setFocus={setFocus}
                index={3}
                focus={focus === 3}
              />
              <br></br>
              <br></br>
              <MenuButton
                large
                ref={React.createRef()}
                value="Terug"
                labelText="Terug"
                onClick={navigateBack}
                onFocus={playBack}
                setFocus={setFocus}
                index={4}
                focus={focus === 4}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(BackgroundSettings);
